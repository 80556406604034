exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-devices-[id]-details-tsx": () => import("./../../../src/pages/devices/[id]/details.tsx" /* webpackChunkName: "component---src-pages-devices-[id]-details-tsx" */),
  "component---src-pages-devices-index-tsx": () => import("./../../../src/pages/devices/index.tsx" /* webpackChunkName: "component---src-pages-devices-index-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-networksharing-tsx": () => import("./../../../src/pages/networksharing.tsx" /* webpackChunkName: "component---src-pages-networksharing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

