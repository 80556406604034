import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ApiKey, Device, User, SharingRequest } from "./contract"

export interface TokenParams {
  token?: string
}

export interface DeleteDeviceParams extends TokenParams {
  deviceId: string
}

export interface GetApiKeysParams extends TokenParams {
  deviceId: string
}

export interface CreateApiKeyParams extends TokenParams {
  deviceId: string
  description: string
}

export interface DeleteApiKeyParams extends TokenParams {
  deviceId: string
  apiKeyId: string
}

export interface SharingRequestParams extends TokenParams {
  RequestorNetworkIDs: string[]
  AccepterUser: string
}

export interface DeleteSharingRequestParams extends TokenParams {
  sharingRequestID: string
}

export interface SharingResponseParams extends TokenParams {
	NetworkSharingRequestID: string
	Accepted: boolean
	AccepterNetworkIDs: string[]
}

function buildHeaders(token?: string) {
  return (
    (token && {
      Authorization: `Bearer ${token}`,
    }) ||
    {}
  )
}

export const portierApi = createApi({
  reducerPath: "portierApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.portier.dev/api" }),
  tagTypes: ["User", "Devices", "ApiKeys", "SharingRequests", "SharedDevices"],
  endpoints: builder => ({
    getUser: builder.query<User[], TokenParams>({
      query: args => ({
        url: `/user`,
        headers: buildHeaders(args.token),
      }),
      transformResponse: (response: User) => {
        return [response]
      },
      providesTags: ["User"],

    }),
    getDevices: builder.query<Device[], TokenParams>({
      query: args => ({
        url: `/devices`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["Devices"],
    }),
    deleteDevice: builder.mutation<void, DeleteDeviceParams>({
      query: args => ({
        url: `/device/${args.deviceId}`,
        method: "DELETE",
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["Devices"],
    }),
    getApiKeys: builder.query<ApiKey[], GetApiKeysParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikeys`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["ApiKeys"],
    }),
    createApiKey: builder.mutation<ApiKey, CreateApiKeyParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikey`,
        method: "POST",
        body: {
          DeviceGUID: args.deviceId,
          Description: args.description,
        },
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["ApiKeys"],
    }),
    deleteApiKey: builder.mutation<void, DeleteApiKeyParams>({
      query: args => ({
        url: `/device/${args.deviceId}/apikeys/${args.apiKeyId}`,
        method: "DELETE",
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["ApiKeys"],
    }),
    getSharingRequests: builder.query<SharingRequest[], TokenParams>({
      query: args => ({
        url: `/sharingrequests`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["SharingRequests"],
    }),
    postSharingRequest: builder.mutation<SharingRequest, SharingRequestParams>({
      query: args => ({
        url: `/sharingrequests`,
        method: "POST",
        body: {
          RequestorNetworkIDs: args.RequestorNetworkIDs,
          AccepterUser: args.AccepterUser,
        },
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["SharingRequests"],
    }),
    deleteSharingRequest: builder.mutation<void, DeleteSharingRequestParams>({
      query: args => ({
        url: `/sharingrequests/${args.sharingRequestID}`,
        method: "DELETE",
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["SharingRequests"],
    }),
    postSharingResponse: builder.mutation<void, SharingResponseParams>({
      query: args => ({
        url: `/sharingresponse`,
        method: "POST",
        body: {
          NetworkSharingRequestID: args.NetworkSharingRequestID,
          Accepted: args.Accepted,
          AccepterNetworkIDs: args.AccepterNetworkIDs,
        },
        headers: buildHeaders(args.token),
      }),
      invalidatesTags: ["SharingRequests"],
    }),
    getSharedDevices: builder.query<Device[], TokenParams>({
      query: args => ({
        url: `/shareddevices`,
        headers: buildHeaders(args.token),
      }),
      providesTags: ["SharedDevices"],
    }),
  }),
})

export const {
  useGetUserQuery,
  useGetDevicesQuery,
  useDeleteDeviceMutation,
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetSharingRequestsQuery,
  usePostSharingRequestMutation,
  useDeleteSharingRequestMutation,
  usePostSharingResponseMutation,
  useGetSharedDevicesQuery,
} = portierApi
